import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousels = document.querySelectorAll('.eventsCarousel');

  if (0 < carousels.length) {
    carousels.forEach((carousel) => {
      new Swiper(carousel, {
        slidesPerView: 'auto',
        spaceBetween: 24,
        pagination: {
          el: carousel.querySelector('.swiper-pagination'),
          clickable: true,
          type: 'fraction',
        },
        navigation: {
          prevEl: carousel.querySelector('.swiper-button-prev'),
          nextEl: carousel.querySelector('.swiper-button-next'),
        },
        a11y: {
          enabled: true,
          prevSlideMessage: 'Diapositive précédente',
          nextSlideMessage: 'Diapositive suivante',
          paginationBulletMessage: 'Aller à la diapositive {{index}}',
        },
      });
    });
  }

  function moveButton() {
    const btnArrow = document.querySelector('.events-slider-block__btn--arrow');
    const swiper = document.querySelector('.eventsCarousel');
    const titleInner = document.querySelector(
      '.events-slider-block__title-inner'
    );

    if (window.innerWidth < 1200) {
      if (btnArrow && swiper) {
        swiper.appendChild(btnArrow);
      }
    } else {
      titleInner.appendChild(btnArrow);
    }
  }

  // Initial check
  moveButton();

  // Check on resize
  window.addEventListener('resize', moveButton);
});
